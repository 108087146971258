import React from 'react';
import './Contact.css';
import Button from '../Button/Button';
import { EmailButton } from '../Button/EmailButton';

const Contact = () => {
  return (
    <div id='contact' className='contact-container'>
      <div className='contact-content'>
        <div className='contact-headers'>
          <h2 className='contact-header'>Want to get in touch?</h2>
          <h2 className='contact-header secondary-header'>Connect with me.</h2>
        </div>
        <div className='buttons-container'>
          <Button
            background='#0A66C2'
            link='https://www.linkedin.com/in/zach-harrison-333974176/'
          >
            <svg
              className='linkedin-logo'
              xmlns='http://www.w3.org/2000/svg'
              width='1000'
              height='250'
              viewBox='0 0 1000 250'
              fill='white'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M981.893 -3.05176e-05H768.845C758.655 -3.05176e-05 750 8.05949 750 17.9881V232C750 241.94 755.679 250 765.869 250H978.917C989.119 250 1000 241.94 1000 232V17.9881C1000 8.05949 992.095 -3.05176e-05 981.893 -3.05176e-05Z'
                // fill='white'
                fill='none'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M981.893 -3.05176e-05H768.845C758.655 -3.05176e-05 750 8.05949 750 17.9881V232C750 241.94 755.679 250 765.869 250H978.917C989.119 250 1000 241.94 1000 232V17.9881C1000 8.05949 992.095 -3.05176e-05 981.893 -3.05176e-05ZM845.238 95.2381H878.893V112.393H879.262C884.393 103.143 899.548 93.75 918.286 93.75C954.25 93.75 964.286 112.845 964.286 148.214V214.286H928.571V154.726C928.571 138.893 922.25 125 907.464 125C889.512 125 880.952 137.155 880.952 157.107V214.286H845.238V95.2381ZM785.714 214.286H821.429V95.2381H785.714V214.286ZM825.893 53.5714C825.894 56.5023 825.318 59.4047 824.198 62.1129C823.077 64.8211 821.434 67.2821 819.362 69.3553C817.291 71.4285 814.831 73.0732 812.124 74.1957C809.416 75.3181 806.514 75.8962 803.583 75.897C800.652 75.8978 797.75 75.3212 795.042 74.2002C792.334 73.0792 789.873 71.4358 787.801 69.3637C785.728 67.2916 784.083 64.8315 782.962 62.1239C781.84 59.4163 781.262 56.5142 781.262 53.5833C781.261 47.6656 783.611 41.99 787.795 37.8048C791.979 33.6197 797.654 31.2677 803.571 31.2661C809.489 31.2645 815.165 33.6135 819.351 37.7964C823.537 41.9794 825.89 47.6537 825.893 53.5714Z'
                // fill='#0A66C2'
                fill='white'
              />
              <path
                d='M714.286 214.286H680.952V200.107H680.595C673.179 208.702 660.298 215.774 638.464 215.774C608.702 215.774 583.071 193.381 583.071 154.893C583.071 118.226 608.333 93.75 639.583 93.75C658.929 93.75 670.94 100.631 678.214 109.417H678.571V35.7143H714.286V214.286ZM648.56 120.536C628.143 120.536 616.667 135.095 616.667 154.452C616.667 173.821 628.143 188.988 648.56 188.988C669 188.988 680.952 174.214 680.952 154.452C680.952 134.143 669 120.536 648.56 120.536ZM567.393 195.107C558.964 206.024 541.607 215.774 513.583 215.774C476.202 215.774 452.393 191.012 452.393 152.119C452.393 117.56 473.952 93.75 514.631 93.75C549.762 93.75 571.429 116.821 571.429 157.405C571.429 161.452 570.774 165.476 570.774 165.476H486.083L486.286 169.155C488.631 179.417 496.381 191.964 514.167 191.964C529.69 191.964 540.333 183.619 545.143 177.083L567.393 195.107ZM537.06 142.845C537.298 130.298 528.083 117.56 513.56 117.56C496.274 117.56 487.048 131.06 486.083 142.857H537.06V142.845Z'
                // fill='#0A66C2'
                fill='white'
              />
              <path
                d='M452.381 95.2381H410.714L369.048 142.857V35.7143H333.333V214.286H369.048V154.762L413.083 214.286H455.25L404.762 149.202L452.381 95.2381ZM190.476 95.2381H224.131V112.393H224.5C229.631 103.143 244.786 93.75 263.524 93.75C299.488 93.75 309.524 116.571 309.524 148.214V214.286H273.81V154.726C273.81 137.202 267.488 125 252.702 125C234.75 125 226.19 139.214 226.19 157.107V214.286H190.476V95.2381ZM130.952 214.286H166.667V95.2381H130.952V214.286ZM148.821 75C151.677 75.0673 154.518 74.563 157.176 73.5167C159.834 72.4703 162.256 70.9031 164.299 68.9072C166.343 66.9112 167.967 64.5269 169.075 61.8942C170.184 59.2616 170.755 56.4339 170.755 53.5774C170.755 50.7208 170.184 47.8931 169.075 45.2605C167.967 42.6279 166.343 40.2435 164.299 38.2476C162.256 36.2516 159.834 34.6844 157.176 33.6381C154.518 32.5918 151.677 32.0874 148.821 32.1548C143.227 32.2866 137.907 34.6016 133.997 38.6047C130.087 42.6079 127.898 47.9816 127.898 53.5774C127.898 59.1731 130.087 64.5469 133.997 68.55C137.907 72.5532 143.227 74.8681 148.821 75ZM35.7143 35.7143H2.28882e-05V214.286H107.143V178.571H35.7143V35.7143Z'
                // fill='#0A66C2'
                fill='white'
              />
            </svg>
          </Button>
          <EmailButton />
        </div>
      </div>
    </div>
  );
};

export default Contact;
